import { wrapRootElement as wrap } from './src/components/wrapRootElement';
export const wrapRootElement = wrap;
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const onServiceWorkerUpdateReady = () => {
  window.location.reload();
};
// exports.onClientEntry = () => {};
//
// exports.onInitialClientRender = () => {};

// import {jQuery as $} from '../js/jquery.min.js';
// import './src/static/js/jquery-3.1.1.min.js';
